@import "../../styles/custom";

.quote-container {
  display: flex;
  justify-content: space-between;
  margin-left: 156px;
  margin-right: 156px;
  margin-top: 16px;
  margin-bottom: 96px;
  max-width: calc(100vw - 128px);
  height: 505px;
  background-color: $business-800;
  border-radius: 16px;

  @include media-breakpoint-up(xl) {
    padding: 48px 72px;
  }

  @include media-breakpoint-down(xl) {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 40px 24px 48px 24px;
    margin-left: 16px;
    margin-right: 16px;
    max-width: calc(100vw - 32px);
  }

  @include media-breakpoint-down(md) {
    height: auto;
    min-height: 440px;
    margin-bottom: 48px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 380px;
  }

  &__user-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    
    @include media-breakpoint-down(xl) {
      text-align: center;
      max-height: 100%;
      align-items: center;
    }
  }

  .quote-container__icon {
    width: 55px;
    height: 38px;
    color: $business-300;
    font-size: 20px;
  }

  .quote-container__text {
    min-height: 235px;
    max-height: 235px;
    margin: 40px 0 32px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
    letter-spacing: -0.2px;
    color: $white;
    overflow-y: auto;

    @include media-breakpoint-up(xl) {
      width: 830px;
    }

    @include media-breakpoint-down(xl) {
      margin: 32px auto;
      font-size: 32px;
      line-height: 140%;
      min-height: 200px;
      max-height: 200px;
    }

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      min-height: 180px;
      max-height: 180px;
    }

    @include media-breakpoint-down(md) {
      font-size: 24px;
      text-align: center;
      min-height: 160px;
      max-height: 160px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      min-height: 140px;
      max-height: 140px;
    }
  }

  .quote-container__user-details {
    display: flex;
    align-items: center;
    gap: 24px;
    
    @include media-breakpoint-down(xl) {
      flex-direction: column;
      gap: 16px;
    }

    &--content {
      display: flex;
      flex-direction: column;
    }

    .quote-container__user-details--username {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: $white;
    }

    .quote-container__user-details--job {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: $gray-300;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    
    @include media-breakpoint-down(xl) {
      margin: 40px auto 0;
    }

    @include media-breakpoint-down(md) {
      margin: 24px auto 0;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.quote-container .quote-container__buttons {
  display: flex;
  flex: 1;
  
  @include media-breakpoint-only(lg) {
    display: none;
  }
  
  @include media-breakpoint-only(md) {
    display: none;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: flex-end;
    width: auto;
    margin-bottom: 0;
  }
  
  &--button:first-child {
    margin-right: 8px;
  }
  
  &--button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $business-700;
    border: none;
    color: $business-600;

    @include media-breakpoint-down(md) {
      width: 40px;
      height: 40px;
      color: $white;
      background-color: $business-600;
    }
  }

  &__active-button {
    background-color: $business-600;
    color: $white;
  }
}

.quote-container .quote-container__dots {
  display: flex;
  margin-left: auto;

  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: auto;
    order: -1;
  }

  &--dot {
    width: 8px;
    height: 8px;
    background-color: $business-700;
    border-radius: 50%;
    margin-right: 4px;
    cursor: pointer;
  }

  &__active-dot {
    background-color: $white;
  }
}

body.arabic {
  .quote-container__buttons {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    flex-direction: row-reverse;
  }
}
