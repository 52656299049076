@import "../../styles/custom";

.icon-card {
  padding-top: 32px;
  padding-bottom: 32px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  &.whyUsing {
    padding-inline-end: 64px;

    @include media-breakpoint-up(lg) {
      margin-inline-end: 24px;
    }

    .icon-card__description {
      margin-bottom: 0;  
      span {
        font-weight: bold;
        padding-inline-end: 0px;
      }
    }
    
    ::after {
      content: "";
      position: absolute;
      top: 100%;
      inset-inline-end: 0;
      display: block;
      height: 1px;
      width: 100%;
      background-color: $gray-200;

      @include media-breakpoint-up(lg) {
        top: 0;
        height: 100%;
        width: 1px;
      }
    }
    .icon-card__icon-container {
      background-color: $business-600;
    }
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  @include media-breakpoint-up(lg) {
    display: block;
    padding-bottom: 0;
    padding-top: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    inset-inline-end: 0;
    display: block;
    height: 1px;
    width: 100%;
    background-color: $gray-200;

    @include media-breakpoint-up(lg) {
      width: 0px;
    }
  }

  & &__icon-container {
    height: 64px;
    width: 64px;
    min-width: 64px;
    margin-inline-end: 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $business-100;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }
  } 

  & &__description {
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }

    span {
      font-weight: bold;
        &::after {
          @include media-breakpoint-up(lg) {
            content: "\A";
            white-space: pre;
          }
        }
    }
  }
}
